<template>
    <div>
        <div class="breadcrumb mb-0 row pb-2">
            <div class="col-sm-6">
                <h1>Nueva Facturacion</h1>
            </div>
            <div class="col-sm-4">
                <div class="row d-flex justify-content-end align-items-center">
                    <div class="col-5 th-custom-color text-white py-2 mr-1"><p class="m-0 p-0"><strong>Mes Anterior:</strong></p>
                        <p class="m-0 p-0"> 4,665.80</p></div>
                    <div class="col-5 th-custom-color text-white py-2"><p class="m-0 p-0"><strong>Mes Actual:</strong></p>
                        <p class="m-0 p-0"> 16,435.00 </p></div>
                </div>
            </div>
            <div class="col-sm-2 d-flex justify-content-end align-items-rigth">
                <span>T.C.:</span>
                <span >
                    <strong> USD:6.97 </strong>
                </span>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-6">
                                <button class="btn th-custom-color mr-2"
                                type="button">Guardar</button>
                                <button class="btn th-custom-color mr-2"
                                type="button">Guardar y Nuevo</button>
                                <router-link :to="{ name: 'facturacion-index' }">
                                    <button type="button" class="btn btn-secondary m-1">
                                        Regresar
                                    </button>
                                </router-link>
                            </div>
                            <!-- <div  class="col-sm-2 offset-sm-4 mt-3 pl-2">
                                <div class="pl-5">
                                    <strong>Cot. Nº 154</strong>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-5 pr-0">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label text-right">Sucursal<span
                                        class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <multiselect
                                            v-model="almacen"
                                            track-by="nombre"
                                            label="nombre"
                                            placeholder="Sucursal"
                                            :options="almacenes"
                                            :allow-empty="false"
                                            :searchable="true"
                                            @select="resetByChange">
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                {{ option.sucursal.nombre_comercial }} - {{ option.nombre }}
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                {{ props.option.sucursal.nombre_comercial }} - {{ props.option.nombre }}
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <TheModalPersona  :tipoPersonas="tipoPersonas"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-4">
                                        <multiselect
                                            v-model="indentificacionSelected"
                                            track-by="nombre"
                                            label="nombre"
                                            placeholder="Nit"
                                            :options="tipoIdentificacions"
                                            :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                {{ option.nombre }}
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                {{ props.option.nombre }} - <span style="color: red; font-weight: bold">{{ props.option.descripcion }}</span>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="col-sm-3">
                                        <input
                                            style="width:123px; text-align: center;"
                                            type="text"
                                            class="form-control"
                                            v-model="facturaNit"
                                            placeholder="Nit"
                                            @keydown="validarCaracter"
                                            @blur="reValidarNit"
                                        >
                                    </div>
                                    <div class="col-sm-3" style="left:5px;">
                                        <input type="text" class="form-control"
                                            style="width:120px;"
                                            placeholder="Razon Social" 
                                            v-model="facturaNombre">
                                    </div>
                                     <div class="col-sm-2 pl-0" style="left:50px;">
                                        <TheModalEditUser/>
                                       
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-4" style="margin-top: -10px;">
                                        <span >Cel: 79477996 </span>
                                    </div>
                                    <div class="col-sm-4" style="margin-top: -10px;">
                                        <span style="white-space: nowrap;">Mail: ejeplo@gmail.com</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-1 pl-0 pr-0"></div>
                            <div class="col-sm-2 pr-1">
                                <div class="row form-group">
                                    <div class="col-sm-12 text-center">
                                        <label title="Su empresa no esta conectado con el sistema de Facturacion-SIAT">Sin Conexion SIAT:</label>
                                        <i class="i-Record-2"
                                            title="Su empresa no esta conectado con el sistema de Facturacion-SIAT"
                                            style="font-size: 18px; -webkit-border-radius: 50%; background:red; color:red; cursor:pointer;"></i>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button @click="changeInvoiceType(1);"
                                            class="btn"
                                            style="width:190px;"
                                            v-if="tipoFactura == 1">
                                            NORMAL
                                        </button>
                                        <button @click="changeInvoiceType(2);"
                                            v-if="tipoFactura == 2"
                                            style="width:190px;"
                                            class="btn btn-warning">
                                            CONTROL TRIBUTARIO
                                        </button>
                                        <button @click="changeInvoiceType(3);"
                                            v-if="tipoFactura == 3"
                                            style="width:190px;"
                                            class="btn btn-success">
                                            CASO ESPECIAL 
                                        </button>
                                        <button @click="changeInvoiceType(0);"
                                            v-if="tipoFactura == ''"
                                            :disabled="false"
                                            style="width:190px;"
                                            class="btn btn-dark">
                                            VENTAS MENORES DEL DIA 
                                        </button>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="col-sm-12 pl-0 pr-0"> Standar</label>
                                    </div>
                                    <div class="col-sm-6 pl-0 pr-0">
                                        <input type="text" class="form-control"
                                            placeholder="N° Factura"
                                            :readonly="!facturaNumeroManual"
                                            @click.ctrl="setFacturaManual"
                                            v-model="facturaNumero">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-5 pl-0">
                                        <button class="btn btn-danger">Deuda</button>
                                    </div>
                                    <div class="col-sm-6">
                                        <button class="btn ml-2 btn-dark" disabled >Contigencia</button>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-sm-4 pl-3">
                                <!-- <div class="form-group row">
                                    <label class="col-sm-4 col-form-label text-right">Moneda: <span
                                        class="text-danger">*</span></label>
                                    <div class="col-sm-5 px-0">
                                        <date-picker
                                            v-model="fecha"
                                            lang="es"
                                            format="DD/MM/YYYY"
                                            type="date"
                                            value-type="date"
                                            width="100%"
                                            @change="resetByChange">
                                        </date-picker>
                                    </div>
                                    <div class="col-sm-3">
                                        <button
                                            class="btn btn-secondary btn-block">
                                            Cotizacion
                                        </button>
                                    </div>
                                </div> -->
                                <div class="row form-group">
                                    <label class="col-sm-4 col-form-label text-right">Moneda:</label>
                                    <div class="col-sm-5 pl-0">
                                        <input type="text" class="form-control" placeholder="Boliviano - Bs">
                                        <!-- <multiselect
                                            v-model="moneda"
                                            track-by="name"
                                            label="name"
                                            placeholder="Moneda"
                                            :options="monedas"
                                            :allow-empty="false">
                                        </multiselect> -->
                                    </div>
                                    <div class="col-sm-3" style="padding-left: 0px">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="T/C"
                                            id="tc"
                                            required
                                        >
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <TheVendedor/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-sm-12">
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr class="text-center th-custom-color">
                                            <th width="10%">Código</th>
                                            <th width="21%">Tipo Producto/Servicio (SIN)</th>
                                            <th width="30%">Descripción</th>
                                            <th width="8%">Cantidad</th>
                                            <th width="10%">P/U</th>
                                            <th width="8%">Desc.</th>
                                            <th width="10%">Total</th>
                                            <th width="3%"></th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <div class="form-group row">
                                            <div class="col-sm-8" style="border-right: 1px solid white;">
                                                <TheModalAddProduct/>
                                            </div>
                                            <div class="col-sm-4">
                                                <span class="font-weight-bold">Metodo de Pago:</span>
                                                 <button type="button" class="btn btn-warning m-1" style="width:200px; height: 40px">
                                                    Efectivo
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-6" style="padding: inherit;">
                                                <div class="col-sm-12">
                                                    <b-form-textarea
                                                        id="glosa"
                                                        class="form-control no-resize"
                                                        placeholder="Ingrese alguna descripcion de la venta"
                                                        rows="3"
                                                        max-rows="4"
                                                        style="overflow-y: auto;"
                                                    ></b-form-textarea>
                                                </div>
                                                <br>
                                                
                                            </div>
                                            <div class="col-sm-6" style="padding-top:3px;">
                                                <TheModalPago/>
                                                <!-- <button class="btn btn-danger mt-1">Forma de Pago</button> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4 text-center" style="margin:auto;">
                                        <div class="form-group row">
                                            <div class="col-sm-8">
                                                <div class="form-group row">
                                                    <label>Metodo de pago</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-4 text-right"
                                    style="margin:auto;">
                                        <div class="form-group row">
                                            <div class="col-sm-10"
                                            style="margin:auto; left:20px;">
                                                <table class="table table-borderless" with="100%">
                                                    <tbody>
                                                        <template>
                                                            <tr>
                                                                <th class="th-total" width="70%">Subtotal:</th>
                                                                <td class="th-total" width="80%">
                                                                    <input type="text" class="form-control" value="0.00" disabled>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th class="th-total ">
                                                                    <input type="checkbox"> (-)Desc:
                                                                </th>
                                                                <td class="th-total">
                                                                    <input type="text" class="form-control" value="0.00" disabled>
                                                                </td>
                                                            </tr>
                                                      
                                                        <tr style="background-color: orange;">
                                                            <th class="th-total" style="left:40px; height: 30px;">Importe Base Credito Fiscal:</th>
                                                            <td class="th-total">
                                                                <input type="text" class="form-control" value="0.00" disabled>
                                                            </td>
                                                        </tr>
                                                        <tr class="table-primary1">
                                                            <th class="th-total">Credito Fiscal:</th>
                                                            <td class="th-total">
                                                                <input type="text" class="form-control" value="0.00" disabled>
                                                            </td>
                                                        </tr>
                                                          </template>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/locale/es';
// import 'vue2-datepicker/index.css';
// import moment from "moment";
import TheModalPersona from '../components/TheModalPersona.vue';
import TheVendedor from '../components/TheVendedor.vue';
import TheModalAddProduct from '../components/TheModalAddProduct.vue';
import TheModalEditUser from '../components/TheModalEditUser.vue';
import TheModalPago from '../components/TheModalPago.vue';
// import TheModalCentroCosto from '@/components/centroCosto/TheModalCentroCosto.vue';

export default {
    name: 'create',
    components: {
        Multiselect,
        TheModalPersona,
        TheVendedor,
        TheModalAddProduct,
        TheModalEditUser,
        TheModalPago,
       
        // TheModalCentroCosto,
        // DatePicker,
    },
    data() {
        return {
            almacen: null,
            almacenes: [],
            tipoPersonas: [],
            indentificacionSelected: null,
            tipoIdentificacions: [{
            "id" : 1,
            "nombre": "Nit - Numero de identificacion"
        },
        {
            "id" : 1,
            "nombre": "CI - Cedula de Identidad"
        },
        {
            "id" : 1,
            "nombre": "CEX - Cedula de Extranjero"
        },
         {
            "id" : 1,
            "nombre": "PASS - pasaporte"
        },
         {
            "id" : 1,
            "nombre": "Otros - Otros Documentos"
        }],
            facturaNit: null,
            facturaNombre: null,
            personaRef: null,
            personType: null,
            empresa: null,
            tipoFactura: 1, //  es modalidad [1 => COMPUTARIZADA, 2 => MANUAL]
            factura: false,
            facturaNumero: null,
            facturaNumeroManual: null,
            // fecha: new Date(moment()),
            monedas: [],
            moneda: null,
        };
    },
    methods: {
        resetByChange() {},
        validarCaracter() {
            const validKeys = new RegExp('^[0-9]*$');
            const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
            if (controlKeys.includes(event.code)) {
                switch (event.code) {
                    case 'KeyX':
                        if (!event.ctrlKey) {
                            event.preventDefault();
                        }
                        break;
                    case 'KeyC':
                        if (!event.ctrlKey) {
                            event.preventDefault();
                        }
                        break;
                    case 'KeyV':
                        if (!event.ctrlKey) {
                            event.preventDefault();
                        }
                        break;
                    default:
                        break;
                }
                return;
            }
            if (!validKeys.test(event.key)) {
                event.preventDefault();
            }
        },
        reValidarNit() {
            if (this.facturaNit === '') {
                return;
            }
            const validKeys = new RegExp('^[0-9]*$');
            if (!validKeys.test(this.facturaNit)) {
                this.facturaNit = '';
            }
        },
        changeInvoiceType(tipo) {
            switch (tipo) {
                case 0:
                    this.factura = true;
                    this.tipoFactura = 1;
                    break;
                case 1:
                    this.factura = true;
                    this.tipoFactura = 2;
                    break;
                case 2:
                    this.tipoFactura = 3;
                    this.factura = true;
                    break;
                case 3:
                    this.tipoFactura = '';
                    this.factura = false;
                    break;

                default:
                    break;
            }
        },
        setNumeroFactura(numero) {
            if (this.factura) {
                if (!this.facturaNumeroManual) {
                    this.facturaNumero = numero;
                }
            } else {
                this.facturaNumero = '';
            }
        },
    },
}
</script>

<style scoped>
.th-total {
    /* vertical-align: inherit; */
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: right !important;
}

tr.td-center-vertical td {
    vertical-align: middle;
}
</style>